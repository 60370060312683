import React from "react"
import styled from "styled-components"
import "@fontsource/material-icons-outlined"
import "@fontsource/yantramanav"
import "../assets/css/global.css"

const resume = () => {

  

  return (
    <Wrapper>
      <div class="section">
        
        <iframe
          src="https://drive.google.com/file/d/1CpWLn5SGxUwa9xDt-bOeWTiBLEIXJjkW/preview"
          loading="eager"
        ></iframe>
        {/* <div class="van_1">
      <pre class="pree_van"><b><i>!</i>!;`'`<font color="#FFFFCC">.ndMMP"(4MMx </font>(```!'<font color="#FFFFCC"> x??n.</font>'``' ;!'<font color="#CCCCCC">.nmMMMbmn.</font>`&lt;!!!!''.&gt;'`;;!!';&lt;!!!!'' ,;-'''`,;!!!'  .-`,;';;!',;;&lt;&gt;';;!''(;;;&gt;'-&lt;;;!!;  ,,
;;`' <font color="#FFFFCC">,MMP</font><font color="#FFDC99">;nn4M&gt;</font><font color="#FFFFCC">4MM </font>!!;-- <font color="#FFFFCC">=M;</font><font color="#FFDC66">%</font><font color="#FFFFCC">;Mb</font> --(('<font color="#CCCCCC"> MMM'</font><font color="#EBDCAA">nx</font><font color="#CCCCCC">)MMP </font><font color="#FFFFCC">.,nn. </font>`!;!''`,;!''`.,;&gt;'<font color="#FFFFCC"> .-==nn. </font>`!!';;!&gt; .! ;!&gt; ;!!'`;!!(  ``<font color="#CCCCCC">,.xnmnnx..</font>`    `  
!!!!;<font color="#FFFFCC"> ,M&gt;</font><font color="#FFDC99">4MMnM"</font><font color="#FFFFCC">dML.</font>''`!;-.<font color="#FFFFCC"> "??""</font>.!;;!<font color="#CCCCCC"> ,MMM?</font><font color="#EBDCAA">4P</font><font color="#CCCCCC">dMM </font><font color="#FFFFCC">MP</font><font color="#FFDC66">)nx</font><font color="#FFFFCC">(Mx </font>!!!!''`,;;!!!'<font color="#FFFFCC"> -.ndMmn."Mx</font>`!&lt;!!! ;!`;!'.!!!';!!''<font color="#CCCCCC"> .nMPPP?</font><font color="#EBDCAA">;nnd;</font><font color="#CCCCCC">?4Mnx `;</font>!''`;.<font color="#FFFFCC">  4ndii=="M" </font>.!!'  `(.,.`--; --;<font color="#CCCCCC"> 44MMMMMMP </font><font color="#FFFFCC">"h</font><font color="#FFDC66">/C)</font><font color="#FFFFCC">uMP </font>!!!!;&lt;!!!!!!!<font color="#FFFFCC">,dnMM</font><font color="#FFFF99">MML`M</font><font color="#FFFFCC">nd4n </font>!!!' ;`;!';!!!';!! <font color="#CCCCCC">,dMP?</font><font color="#EBDCAA">inMMMMMMMMMnn,</font><font color="#CCCCCC">"Tx'
</font>'`&lt;!&gt;; <font color="#FFFFCC">  ????;;-"</font> .((!<font color="#AA7841"> &lt;!'</font>(;,,.`' `'-&lt;!;;;,<font color="#CCCCCC">""</font>,;;!<font color="#FFFFCC"> `"??""  </font>`;!!';;`&lt;!''<font color="#FFFFCC"> 4MPM</font><font color="#FFFF99">MbdCuM</font><font color="#FFFFCC">MPxP </font>`'' &lt;!;!`;!!!;!!! <font color="#CCCCCC">uMMP</font><font color="#EBDCAA">;MMMMMP44444MMMMMnx</font><font color="#CCCCCC">`
</font>`!;;``!! -;;,,,.(!!!!!<font color="#AA7841"> !! </font>``<font color="#FFFFCC">_</font>```!!!!!;,``,;;&lt;!!&gt;;!!!!!!!!''''''`!'-&lt;!!; <font color="#FFFFCC">?h"4M</font><font color="#FFFF99">MM</font><font color="#FFFFCC">PP"d" </font>&lt;!;;!!!!';!&lt;! !!!<font color="#CCCCCC"> dMM?</font><font color="#EBDCAA">;MMM?"</font><font color="#FFCC66">;nn=</font><font color="#EBDCAA">;;uu;"TL"Tb
</font><font color="#BEBEE6">.</font>`!!!;`!!;,`&lt;!!!!!.(`'<font color="#AA7841"> !! </font><font color="#FFFF99">nMMMMx </font>`'((((((!!!!'``<font color="#BEBEE6">,,,,,   -=cccccc,</font>'`&lt;!!!;;,<font color="#FFFFCC">"=+)Cx??  </font>``'``-!!!&gt;!!'!;!!<font color="#CCCCCC"> d"MP</font><font color="#EBDCAA">uMMM(</font><font color="#FFCC66">uMMP'</font><font color="#EBDCAA">uMMMMM;="Mx"
</font><font color="#BEBEE6">$c </font>&lt;!!;'`!!!;;;..`&lt;(('<font color="#AA7841"> !'</font><font color="#FFFF99">;MC</font><font color="#FFDC99">xn</font><font color="#FFFF99">.)h </font>`,,```!- <font color="#BEBEE6">.,,cCCC???$P== `""???===.</font>``' -&lt;!&gt;;;;;;;!!  &lt; <font color="#E6E6CC">_._</font>`' `' ! !!<font color="#CCCCCC"> M4M</font><font color="#EBDCAA">;MMMP</font><font color="#FFCC66">;MMM'</font><font color="#EBDCAA">dMMMMMMM1)4M
</font><font color="#BEBEE6">$$h</font> &lt;!'`!;`!&lt;!!!!!;;,<font color="#AA7841">  ! </font><font color="#FFFF99">M&gt;</font><font color="#FFDC99">4MCM</font><font color="#FFFF99">;M&gt; </font>`&lt;!!'`<font color="#BEBEE6">,d$$$$$P"?$ccc$$$$$$$$$$cc,$$cc,=c,.</font>``!!(&lt;!!-<font color="#E6E6CC"> """"'?P=x </font>' !&gt;<font color="#CCCCCC">'&gt;dM</font><font color="#EBDCAA">dMMM(</font><font color="#FFCC66">4MMM:</font><font color="#EBDCAA">MMMMMMMMr44M:
</font><font color="#BEBEE6">C??h.</font>`!;`!! &lt;(`&lt;!!!!!<font color="#AA7841"> ;! </font><font color="#FFFF99">MM;</font><font color="#FFDC99">??</font><font color="#FFFF99">;dM </font>;!'<font color="#BEBEE6"> ,c$$$$$P",c$$$$$????$$$$$$$$$$$$$$$hc,"$c </font>`!.``<font color="#E6E6CC">./?)CCC(%x.".</font>`!;<font color="#CCCCCC"> L"4</font><font color="#EBDCAA">CMMML</font><font color="#FFCC66">?MMMr</font><font color="#EBDCAA">?MMMMMMF);d)d
</font><font color="#BEBEE6">$$c"$c</font>`'!'`!;`!;;,``'<font color="#AA7841"> !! </font><font color="#FFFF99">""????" </font>;'<font color="#BEBEE6"> ,$$$$P",cc??,$$??$cccccccc,.?h""?h.`"$$$$c`?r</font>`!&gt;<font color="#E6E6CC"> M M"'</font><font color="#FFFF99">CC</font><font color="#E6E6CC">"+`Mx  </font>`!<font color="#CCCCCC"> "M;</font><font color="#EBDCAA">4MMMM;</font><font color="#FFCC66">?MMMx;</font><font color="#EBDCAA">???</font><font color="#FFCC66">;cd</font><font color="#EBDCAA">,MM;M
</font><font color="#BEBEE6">$$$$.?h.</font>`'&lt;.`';,,``!!&gt;<font color="#AA7841">'! </font>!'----'`<font color="#BEBEE6">,c$$$P",d$$P'c$$$cc$$$$$$$$???$$$$c;`??c "$$$$r?.</font>'&gt;<font color="#E6E6CC">'M;M=</font><font color="#FFFF99">MC?M</font><font color="#E6E6CC">,hMMb </font>`! <font color="#CCCCCC"> ?&gt;M</font><font color="#EBDCAA">T4MMMx</font><font color="#FFCC66">?TMMMMMPP"</font><font color="#EBDCAA">;nM'dM
</font><font color="#BEBEE6">"$$$$.`?$c,_</font>' ``!!!!''<font color="#AA7841"> !&gt;</font>-<font color="#BEBEE6">cc ,cc$$$$",c$P',cd$$$??""``"""""?$hc`?."?$c;,`$c`$$$$ $ </font>' <font color="#E6E6CC">"MMnr</font><font color="#FFFF99">4M"</font><font color="#E6E6CC">dMMMM</font> &lt;!! <font color="#CCCCCC">4x'C;</font><font color="#EBDCAA">"MMMbnii;iinndMP"dMM
</font><font color="#BEBEE6">c"?$$$$c,"??$hcc,,. 4d.</font><font color="#AA7841">`! </font><font color="#BEBEE6">"$$$$P"$$$$P",d$$$$P".z-"d$$????h.`?$hc h "$c$c`$c,"$$.?h </font>!.<font color="#E6E6CC">`4MMbndMMPP"</font>.!!!&gt;.<font color="#CCCCCC">`T;"+xi;</font><font color="#EBDCAA">??TTTTT??i</font><font color="#CCCCCC">umMMM"
</font><font color="#BEBEE6">$$c,"$$$$$c,`"?????""?F</font><font color="#AA7841">`!! </font><font color="#BEBEE6">"?",c$$P",c?""``" ;d" z$$"zcd$cc ?."$$c`$.`$$$."$$.`$$'$c</font>`!&gt;;,.<font color="#E6E6CC">"""</font>.,;;'`&lt;!`&lt;!; <font color="#CCCCCC">"Tn;?444MMMMMMMMPP";='
</font><font color="#BEBEE6">"$$$$.?$$$$$$$cd$, d$""</font><font color="#AA7841">`!!! </font><font color="#BEBEE6">cd$$P)z$$"</font><font color="#E6E6CC">xdMCCPn. </font><font color="#BEBEE6">z$$"z$$P)cd"h.' ?$$h&lt;$ $$$hc$$$ $$c?$ </font>`!!!!!!'''`,`'&gt;'!;`&lt;!&gt;;<font color="#CCCCCC">`444MMMMmnmnnr="'</font>.;'
<font color="#BEBEE6">c`?$$$c,`"??""'$$' ?$ </font><font color="#AA7841"> `!!! </font><font color="#BEBEE6">$P"'z$$$%</font><font color="#E6E6CC">-M=M</font><font color="#FFDC99">CMP</font><font color="#E6E6CC">MP </font><font color="#BEBEE6">$"Fd$$$,$$$c$$cz $$"d$-$$$$$$$$,$$P $'</font> .`'';!!!!!!!&gt;.`&lt;.`-.``!;;;,,,.,...,;;'',;'
<font color="#BEBEE6">"?c"?$$$$$cc$$$$$</font><font color="#AA7841"> ;</font><font color="#BEBEE6">'F</font><font color="#AA7841">;! `!! </font><font color="#BEBEE6">zc$$$P""</font>.<font color="#E6E6CC"> "hdMMnP </font><font color="#BEBEE6">c$d $$$Fd$$$$$$$$ $$z$$&lt;$$$$$"$Ld$P"d"</font> ,;,;,;!!',,``&lt;!!;`!;,`-;`''';,,.,,;;;;'',;-
<font color="#BEBEE6">$c"?cc,`"???"$$$$ </font><font color="#AA7841">! </font><font color="#BEBEE6">F</font><font color="#AA7841">`!! `! </font><font color="#BEBEE6">$$??"</font>,;!' .<font color="#FFFFCC">"""</font>. <font color="#BEBEE6"> d$F$$?$$F$$$$"$$$$c`$$$$$"d$$',$$$",c"</font> ````'`!!'''`&lt;!;'`!;`&lt;!!&gt;;`'',;;;,`'' - , - '
<font color="#BEBEE6">"?$hc"?$$$$$$$???</font><font color="#AA7841"> !</font><font color="#BEBEE6"> "</font><font color="#AA7841">;!!&gt;.! </font>.,;;!!'`.&lt;!' ;!&gt;<font color="#BEBEE6">,$$L?$,$$h"$$$."$$?$. `"?,c?"'z$P",cF z$$$$$hcc,. -c,</font>`&lt;!;`&lt;!.`''--`&lt;!;,;,.```'`<font color="#CCCCAA">,c',d
</font>;;,<font color="#BEBEE6">""?hcccccccc$$</font><font color="#AA7841"> !! `!!!!! </font>``!!!;;!!' ;!!!;<font color="#BEBEE6">`$$$$$$$?$.?$$L `$h`?c "?ccc$$Czr=" z$$$$CCC""?$$$c ?c,</font>`!; ``!!!!!!;''   `'`<font color="#CCCCAA">,c$$",$P
</font>!'``'-.<font color="#BEBEE6">`"""""""" </font><font color="#AA7841"> !!, &lt;!'!,;! </font>`;&lt;!!!,;&lt;!!!!! <font color="#BEBEE6">`?$$c?$'$$.?$$L.`?.."===.,cC,.,ccd$$??"_.,.`"=.`?$cc"$,</font>`! !;;;&gt;<font color="#CCCCAA">  .,ccd$P",d$$",d",c
</font>!<font color="#CCCCCC">.;dM=.</font>`!!!!'`<font color="#E6E6CC">;;n</font><font color="#AA7841"> !!!&gt;'!&gt;'!!! </font>!!'`'!(;!''`<font color="#FFFFFF">.,.. </font><font color="#BEBEE6">"??c"?cC??$$$$hc$$$ccc$P"'Czd$$??",c$??$$$h."h ?$$h&lt;$ </font>!'!!' <font color="#CCCCAA"> c$$$FLcccd$P".d$cd$$
</font> <font color="#CCCCCC"> 4?MM?M </font>;!!<font color="#E6E6CC"> x"xn;)</font><font color="#AA7841">`!!! !! !!!</font> `;`&lt;!!'<font color="#FFFFFF"> .= d??"TTbmn.</font><font color="#BEBEE6">"4cC$cc,"?$$$$$$$hcd$$$$??".r,d" z-,$$$$ ?L.?$$4" </font>!;!  <font color="#CCCCAA"> d$$"zd$$$$P,c$$$$$F;$
</font>!.<font color="#CCCCCC">`""" </font> &lt;!! <font color="#E6E6CC">MnMMMP</font><font color="#AA7841"> `!!&gt;'!,`!!&gt;</font>' .&lt;! <font color="#FFFFFF">.P.n"</font><font color="#FFFFCC">;nMMmn</font><font color="#FFFFFF">`40Cx</font><font color="#BEBEE6"> $$$$$$$hccc`??????""".,r='z" c$$ $P&lt;$$ d$),$$)'</font>&lt;!'.'<font color="#CCCCAA"> d$P'd$$$$$Cr?$$$P",d$$
</font>;;!!!!!!;!! <font color="#E6E6CC">"bn"L</font><font color="#AA7841"> ; `&lt;! !!'!!! </font>`')&gt;<font color="#FFFFFF">.P'H&gt;</font><font color="#FFFFCC">dP?;?44Mn;</font><font color="#FFFFFF">"Mr </font><font color="#BEBEE6">`""?c`"??h."??"',cc="=,r="</font>; <font color="#BEBEE6">$$F." ??"z$F,d$Fj </font>'<font color="#CCCCAA"> z",$$$L$$$$$$P"z$$P"-"""""
</font>!!,,`'`!;;(!;,<font color="#E6E6CC">""</font><font color="#AA7841">  `!&gt; !;!!;!!! </font>;`!&gt;<font color="#FFFFFF">4h`H</font><font color="#FFFFCC">nM=MMMMb?MMM</font><font color="#FFFFFF">'M,</font><font color="#BEBEE6">"$c,`""====ccd???" ,-  </font>,;!! <font color="#BEBEE6">`$h`=c===='c$$$F</font><font color="#CCCCAA"> ,c" z$$$$$$$"?$"z$" </font>.,.; ,;!!
!!'''''-;,``&lt;!!!'<font color="#AA7841"> ;`!&gt; !!!'!!! </font>;&lt;!'<font color="#FFFFFF">`K H</font><font color="#FFFFCC">MPuMM,MM&gt;dMM</font><font color="#FFFFFF">'MP </font>.<font color="#BEBEE6">`""??hccc??",=="" </font>-!!''.!!;<font color="#BEBEE6"> "?c,dd$$$??"</font><font color="#CCCCAA">.zd$$$P";$$$$P"zP,$$$c </font>`!! ;!!!!
<font color="#CCCCAA"> .=ccccc,</font>'`'-.`'<font color="#AA7841"> &lt;! &lt;! `!!'!!! </font>`!!!<font color="#FFFFFF"> H,</font><font color="#FFFFCC">4Mb;?4MMP;MP"</font><font color="#FFFFFF">.L.</font>''`!!;;,,,.,.,;;;',ccccc `''``<font color="#CCCCAA">.,..,.,,,cc$$$$P"zd$$$$P",d";"</font>..&gt; ;,.``''!!!
<font color="#CCCCAA">'- .,$$$$$$cc,</font>`!<font color="#AA7841"> &lt;!&gt; !! `!'!! &gt;</font>'! `;<font color="#FFFFFF">'"x</font><font color="#FFFFCC">"TMMmnmMP</font><font color="#FFFFFF">,n88F </font> `'--````<font color="#CCCCAA">_,zc . ,cd?"?$F%,c$$$$$$??';$$$$$??,c$$$$$P",dP'dF</font> !!;!!!!!!!!!`,
<font color="#CCCCAA">cc$$hcccccc"?$h.</font><font color="#AA7841"> !!! !!! !!! &lt;! </font>' &lt;'<font color="#FFFFFF">  "Hmnmnn,,n??</font><font color="#CCCCAA"> .,ccc$$????$$CCCD ?",cd$$$??$$$$P',cc$$$$P",zc$$$$P"" =""" </font>.,.&lt;!!!!!';;!!';!!
<font color="#CCCCAA">$$$CCC??$$$$cCC?</font><font color="#AA7841"> &lt;!!.`!! `!! !!  &gt;</font>!' .<font color="#FFFFFF">'"""???"'</font><font color="#CCCCAA">.,c$$$??";cc$$??"_,ccd$?$$$P".zdP",cd?????",zd$???""""</font>.,;&gt; ;!!!!!''``,,.zccc=,ccc
<font color="#CCCCAA">$$$$$$$hc"?$$$$h.</font><font color="#AA7841">`!!! !!! !! !! ;'</font><font color="#999999">!'</font><font color="#AA7841">; </font><font color="#CCCCAA">?$$$$$$????"',cd$$P",cc$$$$$P",c$$" ,dP"" ""</font>.,;- .,..  ,;;&lt;!!!'!!!&gt;&lt;!';'`.zcF,c$$$P"cd$P",
<font color="#CCCCAA">$c,cCC"?$$."?$$$L</font><font color="#AA7841"> !!! `!!!!! !!!!</font><font color="#999999"> !'</font><font color="#AA7841">!; </font><font color="#CCCCAA">"Lccccchc$$??""zcd$$$$$$P",z$$$$$</font><font color="#CCCCFF"> A</font><font color="#CCCCAA">`"</font>,;&gt;',;!!!'``````&lt;.`'''`,;'`.,ccr zc$",$$$$FCc$$$P,$$
<font color="#CCCCAA">$$$$$$$hcc"?c,"??</font><font color="#AA7841"> `!! !!!!!!.&lt;!!!</font><font color="#999999"> !'</font><font color="#AA7841">!!!.</font><font color="#CCCCAA">`"$$$$$P".zc$$$$"",.,,cd$$P",c?"</font><font color="#CCCCFF"> A</font>`!!!;!!!'',;!!!!!- .,c$=.,,,$$$PCc$$$Ld$$$$hd$$$$$c$$F
<font color="#CCCCAA">$hc"?$$$C""-"???$$</font><font color="#AA7841"> !! !! !!!!'!!!</font><font color="#999999"> ' </font><font color="#AA7841">`!!!.; </font><font color="#CCCCAA">""_.zd$$??"</font>.,;;,<font color="#CCCCAA"> "??",zr?"</font>,;!<font color="#CCCCFF"> A</font>`!!!!!! &lt;!!!!!'`.zd$?$$d$$$$$"'$$$$FC$$$$P"?$$P"$$$$"c
<font color="#CCCCAA">""?$h."????,,._"""</font><font color="#AA7841"> `.!!!;!!!!;`!!!   !&gt;&lt;! '.</font><font color="#CCCCAA">"?$??""</font>,;!';;;`'- <font color="#CCCCAA">"""</font>.,;!!',<font color="#CCCCFF"> A</font>`!!!!' .&lt;!'`.,c?)?" ,""??".z.."".  `"?$P" ""?" "" ,,`?
!!;,<font color="#CCCCAA">`"??????"'C???</font><font color="#AA7841"> !!!!&gt;&lt;!!!!`!`!!'. `!'!!;`;. </font>,;!!!!!!!!!'--&lt;!!!!'',;!!<font color="#CCCCFF"> A </font>'''  `_..=="'zc",d$$$$ccc$$$$c,L,c$cc,z$$$hc,$$cd$$hc
!!!!!;,<font color="#CCCCAA">"""""""</font>..- <font color="#AA7841">&lt;!!!!!'!!!; !!&lt;!;`! !!`!!&gt;'!;</font> `.,zcc,'  ccc,._,_ `''''<font color="#CCCCFF">.A </font>   ????"--"L  ,c,"??$$??"'"""???""?$$$$???$$$$$$$$$$$
!!!!!'' ,;;!!!'.<font color="#AA7841"> &lt;' ;!'! !!&lt;!; !!!! !&gt;`!;`!!&gt;!!&gt;</font>&lt;$??$$?$cc,"?$$$$$$h.,c<font color="#CCCCFF"> d$h</font><font color="#6699FF">`$$ccc"</font>,cd$c$$$$$ccc,.zcc$$$$$cc$" .,..-= .. ??"""???
!!' ,;!!!!'``.;<font color="#AA7841"> ;' &lt;!! `;&lt;!'!!; `!!&gt;'! &lt;! !! !!,</font> $$P   `$$$$c,,cLdC"??"<font color="#CCCCFF">.?"?</font><font color="#6699FF"> ?$?????)"?"',</font>`"?$F,$$$P""$$$$$",c$$$,chcc $h. c$$c ,
'  `!!!!!&gt;;!!!'<font color="#AA7841"> ' !!!!&gt; &lt;!!&gt;.`!!;`&lt;!;!, !&gt;!! !!&gt;</font>4P"<font color="#AA7841">,' </font>`hcc$$??????" , <font color="#CCCCFF">?== hc,,</font><font color="#6699FF">dC??$$$$$$$$r </font><font color="#9999FF">.,..</font> ""&lt;$$"'$$c$$$$$$ ?$$$$$,.$"3$h,
!!;;,'```''''' <font color="#AA7841">' !!!!!!&gt; `!!!;'`!; `!!! !&gt;!!'!'  ,;</font> z$r .,. ,i <font color="#9999FF">$h`,c ? </font><font color="#CCCCFF">zP  "$,,,.</font><font color="#9999FF">&lt;$$$$F,cCL."""$$h.</font>`""d$$" $$"",$$.""")C,.cc$$$$
!!!!!!!' ,;!;, <font color="#AA7841"> !!!!'!!!! `!!!!;`!; `!! !&gt;!&gt;' ;!!!! </font>"?"<font color="#CC9966">-?" </font><font color="#EBDCAA">rcc,</font><font color="#9999FF">cF d"</font><font color="#CCCCAA">%  </font><font color="#CCCCFF">?"  c $$$L</font><font color="#9999FF"> $$$P d$$$$$$."$$$$$ </font>c " &lt;$"z$$??$$ "????????""
;;- ,;, !!!!!!<font color="#AA7841"> `!!!! &lt;!!!! &lt;!!!!;`!;'!  !!!! &lt;!!!!&gt;; </font>`;;;-=<font color="#EBDCAA"> ??'</font><font color="#9999FF">?F&lt;$ &lt;$ </font><font color="#CCCCFF">$$. $ ?".?</font><font color="#9999FF">-`"". ???????? .,,</font><font color="#CCCCAA">c</font><font color="#9999FF">,,</font><font color="#CCCCAA">c </font><font color="#9999FF">.,.. </font>"?L"??"'L = 4,'????
!;!!!!'`````!! <font color="#AA7841"> `!!!&gt; &lt;!!!&gt;'!!!!! ! ' ;!!!&gt;!!'!!!!,';.</font> `&lt;!!!!;;;,&gt; ..'<font color="#CCCCFF"> ?"")C</font>,c,c- &lt;$$$h4ccccccc <font color="#9999FF">$$$$$$P $$$$F</font>.,.<font color="#CCCCCC"> ,d$$P"</font>"""???=+-
!```'!<font color="#9999FF"> !!!!; ,; </font><font color="#AA7841">;'`!!; `!!! &lt;!!!!&gt;  ;&lt;!!''&gt;!! `&lt;!!!; `!;,</font>`&lt;!!!!!! z$$$$$ ??????".r`$$$$dCCCCCCC,<font color="#9999FF">zccccc .,cCC</font><font color="#CCCCCC"> d$$$$$$P </font>c$$$c<font color="#9999FF"> ;,;;</font>
<font color="#9999FF">!; '  `!!!!! !! </font><font color="#AA7841">`;.``!!; !!&gt;'!!!! ;!'`!! &lt;`!'';  `!!!; `!&gt;</font> &lt;!!! 4c$$$$$$ ccccccc$" """"""""?$$$$$$$$$$cc,<font color="#9999FF">"??</font><font color="#CCCCCC"> ???????"</font>z$$$$??<font color="#9999FF"> !',</font>
<font color="#9999FF">''' !; ! !!,`;!;  </font><font color="#AA7841">`!.'`!;`! &lt; `! `'' &lt;!';!;! !!&gt; ;`&lt;!!!; !;</font> cccccccc,,,.."$$$$$$P<font color="#9999FF"> $$$"z$$",c </font>?""<font color="#9999FF">.,c </font>$$$".zr ;!!!!!!; $P" <font color="#9999FF">.;;!''`</font>
<font color="#9999FF">;,;!!!;,. &lt;!;;!! </font><font color="#AA7841">!.`!!;,! '   '   .&lt;!!' !!! ;!!&gt; !&gt;.`&lt;!!!!!</font> $$$$CCLcccr?% $$$$$F<font color="#9999FF"> z"?"z$$",$$P&lt;$$$$". </font>,c$$P) !!!!!!!! "<font color="#9999FF">.&lt;'`,';! '</font></b>
</pre>

      </div>
        <div class="van_2">
      <pre class="pree_van"><b><i>!</i>!;`'`<font color="#FFFFCC">.ndMMP"(4MMx </font>(```!'<font color="#FFFFCC"> x??n.</font>'``' ;!'<font color="#CCCCCC">.nmMMMbmn.</font>`&lt;!!!!''.&gt;'`;;!!';&lt;!!!!'' ,;-'''`,;!!!'  .-`,;';;!',;;&lt;&gt;';;!''(;;;&gt;'-&lt;;;!!;  ,,
;;`' <font color="#FFFFCC">,MMP</font><font color="#FFDC99">;nn4M&gt;</font><font color="#FFFFCC">4MM </font>!!;-- <font color="#FFFFCC">=M;</font><font color="#FFDC66">%</font><font color="#FFFFCC">;Mb</font> --(('<font color="#CCCCCC"> MMM'</font><font color="#EBDCAA">nx</font><font color="#CCCCCC">)MMP </font><font color="#FFFFCC">.,nn. </font>`!;!''`,;!''`.,;&gt;'<font color="#FFFFCC"> .-==nn. </font>`!!';;!&gt; .! ;!&gt; ;!!'`;!!(  ``<font color="#CCCCCC">,.xnmnnx..</font>`    `  
!!!!;<font color="#FFFFCC"> ,M&gt;</font><font color="#FFDC99">4MMnM"</font><font color="#FFFFCC">dML.</font>''`!;-.<font color="#FFFFCC"> "??""</font>.!;;!<font color="#CCCCCC"> ,MMM?</font><font color="#EBDCAA">4P</font><font color="#CCCCCC">dMM </font><font color="#FFFFCC">MP</font><font color="#FFDC66">)nx</font><font color="#FFFFCC">(Mx </font>!!!!''`,;;!!!'<font color="#FFFFCC"> -.ndMmn."Mx</font>`!&lt;!!! ;!`;!'.!!!';!!''<font color="#CCCCCC"> .nMPPP?</font><font color="#EBDCAA">;nnd;</font><font color="#CCCCCC">?4Mnx `;</font>!''`;.<font color="#FFFFCC">  4ndii=="M" </font>.!!'  `(.,.`--; --;<font color="#CCCCCC"> 44MMMMMMP </font><font color="#FFFFCC">"h</font><font color="#FFDC66">/C)</font><font color="#FFFFCC">uMP </font>!!!!;&lt;!!!!!!!<font color="#FFFFCC">,dnMM</font><font color="#FFFF99">MML`M</font><font color="#FFFFCC">nd4n </font>!!!' ;`;!';!!!';!! <font color="#CCCCCC">,dMP?</font><font color="#EBDCAA">inMMMMMMMMMnn,</font><font color="#CCCCCC">"Tx'
</font>'`&lt;!&gt;; <font color="#FFFFCC">  ????;;-"</font> .((!<font color="#AA7841"> &lt;!'</font>(;,,.`' `'-&lt;!;;;,<font color="#CCCCCC">""</font>,;;!<font color="#FFFFCC"> `"??""  </font>`;!!';;`&lt;!''<font color="#FFFFCC"> 4MPM</font><font color="#FFFF99">MbdCuM</font><font color="#FFFFCC">MPxP </font>`'' &lt;!;!`;!!!;!!! <font color="#CCCCCC">uMMP</font><font color="#EBDCAA">;MMMMMP44444MMMMMnx</font><font color="#CCCCCC">`
</font>`!;;``!! -;;,,,.(!!!!!<font color="#AA7841"> !! </font>``<font color="#FFFFCC">_</font>```!!!!!;,``,;;&lt;!!&gt;;!!!!!!!!''''''`!'-&lt;!!; <font color="#FFFFCC">?h"4M</font><font color="#FFFF99">MM</font><font color="#FFFFCC">PP"d" </font>&lt;!;;!!!!';!&lt;! !!!<font color="#CCCCCC"> dMM?</font><font color="#EBDCAA">;MMM?"</font><font color="#FFCC66">;nn=</font><font color="#EBDCAA">;;uu;"TL"Tb
</font><font color="#BEBEE6">.</font>`!!!;`!!;,`&lt;!!!!!.(`'<font color="#AA7841"> !! </font><font color="#FFFF99">nMMMMx </font>`'((((((!!!!'``<font color="#BEBEE6">,,,,,   -=cccccc,</font>'`&lt;!!!;;,<font color="#FFFFCC">"=+)Cx??  </font>``'``-!!!&gt;!!'!;!!<font color="#CCCCCC"> d"MP</font><font color="#EBDCAA">uMMM(</font><font color="#FFCC66">uMMP'</font><font color="#EBDCAA">uMMMMM;="Mx"
</font><font color="#BEBEE6">$c </font>&lt;!!;'`!!!;;;..`&lt;(('<font color="#AA7841"> !'</font><font color="#FFFF99">;MC</font><font color="#FFDC99">xn</font><font color="#FFFF99">.)h </font>`,,```!- <font color="#BEBEE6">.,,cCCC???$P== `""???===.</font>``' -&lt;!&gt;;;;;;;!!  &lt; <font color="#E6E6CC">_._</font>`' `' ! !!<font color="#CCCCCC"> M4M</font><font color="#EBDCAA">;MMMP</font><font color="#FFCC66">;MMM'</font><font color="#EBDCAA">dMMMMMMM1)4M
</font><font color="#BEBEE6">$$h</font> &lt;!'`!;`!&lt;!!!!!;;,<font color="#AA7841">  ! </font><font color="#FFFF99">M&gt;</font><font color="#FFDC99">4MCM</font><font color="#FFFF99">;M&gt; </font>`&lt;!!'`<font color="#BEBEE6">,d$$$$$P"?$ccc$$$$$$$$$$cc,$$cc,=c,.</font>``!!(&lt;!!-<font color="#E6E6CC"> """"'?P=x </font>' !&gt;<font color="#CCCCCC">'&gt;dM</font><font color="#EBDCAA">dMMM(</font><font color="#FFCC66">4MMM:</font><font color="#EBDCAA">MMMMMMMMr44M:
</font><font color="#BEBEE6">C??h.</font>`!;`!! &lt;(`&lt;!!!!!<font color="#AA7841"> ;! </font><font color="#FFFF99">MM;</font><font color="#FFDC99">??</font><font color="#FFFF99">;dM </font>;!'<font color="#BEBEE6"> ,c$$$$$P",c$$$$$????$$$$$$$$$$$$$$$hc,"$c </font>`!.``<font color="#E6E6CC">./?)CCC(%x.".</font>`!;<font color="#CCCCCC"> L"4</font><font color="#EBDCAA">CMMML</font><font color="#FFCC66">?MMMr</font><font color="#EBDCAA">?MMMMMMF);d)d
</font><font color="#BEBEE6">$$c"$c</font>`'!'`!;`!;;,``'<font color="#AA7841"> !! </font><font color="#FFFF99">""????" </font>;'<font color="#BEBEE6"> ,$$$$P",cc??,$$??$cccccccc,.?h""?h.`"$$$$c`?r</font>`!&gt;<font color="#E6E6CC"> M M"'</font><font color="#FFFF99">CC</font><font color="#E6E6CC">"+`Mx  </font>`!<font color="#CCCCCC"> "M;</font><font color="#EBDCAA">4MMMM;</font><font color="#FFCC66">?MMMx;</font><font color="#EBDCAA">???</font><font color="#FFCC66">;cd</font><font color="#EBDCAA">,MM;M
</font><font color="#BEBEE6">$$$$.?h.</font>`'&lt;.`';,,``!!&gt;<font color="#AA7841">'! </font>!'----'`<font color="#BEBEE6">,c$$$P",d$$P'c$$$cc$$$$$$$$???$$$$c;`??c "$$$$r?.</font>'&gt;<font color="#E6E6CC">'M;M=</font><font color="#FFFF99">MC?M</font><font color="#E6E6CC">,hMMb </font>`! <font color="#CCCCCC"> ?&gt;M</font><font color="#EBDCAA">T4MMMx</font><font color="#FFCC66">?TMMMMMPP"</font><font color="#EBDCAA">;nM'dM
</font><font color="#BEBEE6">"$$$$.`?$c,_</font>' ``!!!!''<font color="#AA7841"> !&gt;</font>-<font color="#BEBEE6">cc ,cc$$$$",c$P',cd$$$??""``"""""?$hc`?."?$c;,`$c`$$$$ $ </font>' <font color="#E6E6CC">"MMnr</font><font color="#FFFF99">4M"</font><font color="#E6E6CC">dMMMM</font> &lt;!! <font color="#CCCCCC">4x'C;</font><font color="#EBDCAA">"MMMbnii;iinndMP"dMM
</font><font color="#BEBEE6">c"?$$$$c,"??$hcc,,. 4d.</font><font color="#AA7841">`! </font><font color="#BEBEE6">"$$$$P"$$$$P",d$$$$P".z-"d$$????h.`?$hc h "$c$c`$c,"$$.?h </font>!.<font color="#E6E6CC">`4MMbndMMPP"</font>.!!!&gt;.<font color="#CCCCCC">`T;"+xi;</font><font color="#EBDCAA">??TTTTT??i</font><font color="#CCCCCC">umMMM"
</font><font color="#BEBEE6">$$c,"$$$$$c,`"?????""?F</font><font color="#AA7841">`!! </font><font color="#BEBEE6">"?",c$$P",c?""``" ;d" z$$"zcd$cc ?."$$c`$.`$$$."$$.`$$'$c</font>`!&gt;;,.<font color="#E6E6CC">"""</font>.,;;'`&lt;!`&lt;!; <font color="#CCCCCC">"Tn;?444MMMMMMMMPP";='
</font><font color="#BEBEE6">"$$$$.?$$$$$$$cd$, d$""</font><font color="#AA7841">`!!! </font><font color="#BEBEE6">cd$$P)z$$"</font><font color="#E6E6CC">xdMCCPn. </font><font color="#BEBEE6">z$$"z$$P)cd"h.' ?$$h&lt;$ $$$hc$$$ $$c?$ </font>`!!!!!!'''`,`'&gt;'!;`&lt;!&gt;;<font color="#CCCCCC">`444MMMMmnmnnr="'</font>.;'
<font color="#BEBEE6">c`?$$$c,`"??""'$$' ?$ </font><font color="#AA7841"> `!!! </font><font color="#BEBEE6">$P"'z$$$%</font><font color="#E6E6CC">-M=M</font><font color="#FFDC99">CMP</font><font color="#E6E6CC">MP </font><font color="#BEBEE6">$"Fd$$$,$$$c$$cz $$"d$-$$$$$$$$,$$P $'</font> .`'';!!!!!!!&gt;.`&lt;.`-.``!;;;,,,.,...,;;'',;'
<font color="#BEBEE6">"?c"?$$$$$cc$$$$$</font><font color="#AA7841"> ;</font><font color="#BEBEE6">'F</font><font color="#AA7841">;! `!! </font><font color="#BEBEE6">zc$$$P""</font>.<font color="#E6E6CC"> "hdMMnP </font><font color="#BEBEE6">c$d $$$Fd$$$$$$$$ $$z$$&lt;$$$$$"$Ld$P"d"</font> ,;,;,;!!',,``&lt;!!;`!;,`-;`''';,,.,,;;;;'',;-
<font color="#BEBEE6">$c"?cc,`"???"$$$$ </font><font color="#AA7841">! </font><font color="#BEBEE6">F</font><font color="#AA7841">`!! `! </font><font color="#BEBEE6">$$??"</font>,;!' .<font color="#FFFFCC">"""</font>. <font color="#BEBEE6"> d$F$$?$$F$$$$"$$$$c`$$$$$"d$$',$$$",c"</font> ````'`!!'''`&lt;!;'`!;`&lt;!!&gt;;`'',;;;,`'' - , - '
<font color="#BEBEE6">"?$hc"?$$$$$$$???</font><font color="#AA7841"> !</font><font color="#BEBEE6"> "</font><font color="#AA7841">;!!&gt;.! </font>.,;;!!'`.&lt;!' ;!&gt;<font color="#BEBEE6">,$$L?$,$$h"$$$."$$?$. `"?,c?"'z$P",cF z$$$$$hcc,. -c,</font>`&lt;!;`&lt;!.`''--`&lt;!;,;,.```'`<font color="#CCCCAA">,c',d
</font>;;,<font color="#BEBEE6">""?hcccccccc$$</font><font color="#AA7841"> !! `!!!!! </font>``!!!;;!!' ;!!!;<font color="#BEBEE6">`$$$$$$$?$.?$$L `$h`?c "?ccc$$Czr=" z$$$$CCC""?$$$c ?c,</font>`!; ``!!!!!!;''   `'`<font color="#CCCCAA">,c$$",$P
</font>!'``'-.<font color="#BEBEE6">`"""""""" </font><font color="#AA7841"> !!, &lt;!'!,;! </font>`;&lt;!!!,;&lt;!!!!! <font color="#BEBEE6">`?$$c?$'$$.?$$L.`?.."===.,cC,.,ccd$$??"_.,.`"=.`?$cc"$,</font>`! !;;;&gt;<font color="#CCCCAA">  .,ccd$P",d$$",d",c
</font>!<font color="#CCCCCC">.;dM=.</font>`!!!!'`<font color="#E6E6CC">;;n</font><font color="#AA7841"> !!!&gt;'!&gt;'!!! </font>!!'`'!(;!''`<font color="#FFFFFF">.,.. </font><font color="#BEBEE6">"??c"?cC??$$$$hc$$$ccc$P"'Czd$$??",c$??$$$h."h ?$$h&lt;$ </font>!'!!' <font color="#CCCCAA"> c$$$FLcccd$P".d$cd$$
</font> <font color="#CCCCCC"> 4?MM?M </font>;!!<font color="#E6E6CC"> x"xn;)</font><font color="#AA7841">`!!! !! !!!</font> `;`&lt;!!'<font color="#FFFFFF"> .= d??"TTbmn.</font><font color="#BEBEE6">"4cC$cc,"?$$$$$$$hcd$$$$??".r,d" z-,$$$$ ?L.?$$4" </font>!;!  <font color="#CCCCAA"> d$$"zd$$$$P,c$$$$$F;$
</font>!.<font color="#CCCCCC">`""" </font> &lt;!! <font color="#E6E6CC">MnMMMP</font><font color="#AA7841"> `!!&gt;'!,`!!&gt;</font>' .&lt;! <font color="#FFFFFF">.P.n"</font><font color="#FFFFCC">;nMMmn</font><font color="#FFFFFF">`40Cx</font><font color="#BEBEE6"> $$$$$$$hccc`??????""".,r='z" c$$ $P&lt;$$ d$),$$)'</font>&lt;!'.'<font color="#CCCCAA"> d$P'd$$$$$Cr?$$$P",d$$
</font>;;!!!!!!;!! <font color="#E6E6CC">"bn"L</font><font color="#AA7841"> ; `&lt;! !!'!!! </font>`')&gt;<font color="#FFFFFF">.P'H&gt;</font><font color="#FFFFCC">dP?;?44Mn;</font><font color="#FFFFFF">"Mr </font><font color="#BEBEE6">`""?c`"??h."??"',cc="=,r="</font>; <font color="#BEBEE6">$$F." ??"z$F,d$Fj </font>'<font color="#CCCCAA"> z",$$$L$$$$$$P"z$$P"-"""""
</font>!!,,`'`!;;(!;,<font color="#E6E6CC">""</font><font color="#AA7841">  `!&gt; !;!!;!!! </font>;`!&gt;<font color="#FFFFFF">4h`H</font><font color="#FFFFCC">nM=MMMMb?MMM</font><font color="#FFFFFF">'M,</font><font color="#BEBEE6">"$c,`""====ccd???" ,-  </font>,;!! <font color="#BEBEE6">`$h`=c===='c$$$F</font><font color="#CCCCAA"> ,c" z$$$$$$$"?$"z$" </font>.,.; ,;!!
!!'''''-;,``&lt;!!!'<font color="#AA7841"> ;`!&gt; !!!'!!! </font>;&lt;!'<font color="#FFFFFF">`K H</font><font color="#FFFFCC">MPuMM,MM&gt;dMM</font><font color="#FFFFFF">'MP </font>.<font color="#BEBEE6">`""??hccc??",=="" </font>-!!''.!!;<font color="#BEBEE6"> "?c,dd$$$??"</font><font color="#CCCCAA">.zd$$$P";$$$$P"zP,$$$c </font>`!! ;!!!!
<font color="#CCCCAA"> .=ccccc,</font>'`'-.`'<font color="#AA7841"> &lt;! &lt;! `!!'!!! </font>`!!!<font color="#FFFFFF"> H,</font><font color="#FFFFCC">4Mb;?4MMP;MP"</font><font color="#FFFFFF">.L.</font>''`!!;;,,,.,.,;;;',ccccc `''``<font color="#CCCCAA">.,..,.,,,cc$$$$P"zd$$$$P",d";"</font>..&gt; ;,.``''!!!
<font color="#CCCCAA">'- .,$$$$$$cc,</font>`!<font color="#AA7841"> &lt;!&gt; !! `!'!! &gt;</font>'! `;<font color="#FFFFFF">'"x</font><font color="#FFFFCC">"TMMmnmMP</font><font color="#FFFFFF">,n88F </font> `'--````<font color="#CCCCAA">_,zc . ,cd?"?$F%,c$$$$$$??';$$$$$??,c$$$$$P",dP'dF</font> !!;!!!!!!!!!`,
<font color="#CCCCAA">cc$$hcccccc"?$h.</font><font color="#AA7841"> !!! !!! !!! &lt;! </font>' &lt;'<font color="#FFFFFF">  "Hmnmnn,,n??</font><font color="#CCCCAA"> .,ccc$$????$$CCCD ?",cd$$$??$$$$P',cc$$$$P",zc$$$$P"" =""" </font>.,.&lt;!!!!!';;!!';!!
<font color="#CCCCAA">$$$CCC??$$$$cCC?</font><font color="#AA7841"> &lt;!!.`!! `!! !!  &gt;</font>!' .<font color="#FFFFFF">'"""???"'</font><font color="#CCCCAA">.,c$$$??";cc$$??"_,ccd$?$$$P".zdP",cd?????",zd$???""""</font>.,;&gt; ;!!!!!''``,,.zccc=,ccc
<font color="#CCCCAA">$$$$$$$hc"?$$$$h.</font><font color="#AA7841">`!!! !!! !! !! ;'</font><font color="#999999">!'</font><font color="#AA7841">; </font><font color="#CCCCAA">?$$$$$$????"',cd$$P",cc$$$$$P",c$$" ,dP"" ""</font>.,;- .,..  ,;;&lt;!!!'!!!&gt;&lt;!';'`.zcF,c$$$P"cd$P",
<font color="#CCCCAA">$c,cCC"?$$."?$$$L</font><font color="#AA7841"> !!! `!!!!! !!!!</font><font color="#999999"> !'</font><font color="#AA7841">!; </font><font color="#CCCCAA">"Lccccchc$$??""zcd$$$$$$P",z$$$$$</font><font color="#CCCCFF"> A</font><font color="#CCCCAA">`"</font>,;&gt;',;!!!'``````&lt;.`'''`,;'`.,ccr zc$",$$$$FCc$$$P,$$
<font color="#CCCCAA">$$$$$$$hcc"?c,"??</font><font color="#AA7841"> `!! !!!!!!.&lt;!!!</font><font color="#999999"> !'</font><font color="#AA7841">!!!.</font><font color="#CCCCAA">`"$$$$$P".zc$$$$"",.,,cd$$P",c?"</font><font color="#CCCCFF"> A</font>`!!!;!!!'',;!!!!!- .,c$=.,,,$$$PCc$$$Ld$$$$hd$$$$$c$$F
<font color="#CCCCAA">$hc"?$$$C""-"???$$</font><font color="#AA7841"> !! !! !!!!'!!!</font><font color="#999999"> ' </font><font color="#AA7841">`!!!.; </font><font color="#CCCCAA">""_.zd$$??"</font>.,;;,<font color="#CCCCAA"> "??",zr?"</font>,;!<font color="#CCCCFF"> A</font>`!!!!!! &lt;!!!!!'`.zd$?$$d$$$$$"'$$$$FC$$$$P"?$$P"$$$$"c
<font color="#CCCCAA">""?$h."????,,._"""</font><font color="#AA7841"> `.!!!;!!!!;`!!!   !&gt;&lt;! '.</font><font color="#CCCCAA">"?$??""</font>,;!';;;`'- <font color="#CCCCAA">"""</font>.,;!!',<font color="#CCCCFF"> A</font>`!!!!' .&lt;!'`.,c?)?" ,""??".z.."".  `"?$P" ""?" "" ,,`?
!!;,<font color="#CCCCAA">`"??????"'C???</font><font color="#AA7841"> !!!!&gt;&lt;!!!!`!`!!'. `!'!!;`;. </font>,;!!!!!!!!!'--&lt;!!!!'',;!!<font color="#CCCCFF"> A </font>'''  `_..=="'zc",d$$$$ccc$$$$c,L,c$cc,z$$$hc,$$cd$$hc
!!!!!;,<font color="#CCCCAA">"""""""</font>..- <font color="#AA7841">&lt;!!!!!'!!!; !!&lt;!;`! !!`!!&gt;'!;</font> `.,zcc,'  ccc,._,_ `''''<font color="#CCCCFF">.A </font>   ????"--"L  ,c,"??$$??"'"""???""?$$$$???$$$$$$$$$$$
!!!!!'' ,;;!!!'.<font color="#AA7841"> &lt;' ;!'! !!&lt;!; !!!! !&gt;`!;`!!&gt;!!&gt;</font>&lt;$??$$?$cc,"?$$$$$$h.,c<font color="#CCCCFF"> d$h</font><font color="#6699FF">`$$ccc"</font>,cd$c$$$$$ccc,.zcc$$$$$cc$" .,..-= .. ??"""???
!!' ,;!!!!'``.;<font color="#AA7841"> ;' &lt;!! `;&lt;!'!!; `!!&gt;'! &lt;! !! !!,</font> $$P   `$$$$c,,cLdC"??"<font color="#CCCCFF">.?"?</font><font color="#6699FF"> ?$?????)"?"',</font>`"?$F,$$$P""$$$$$",c$$$,chcc $h. c$$c ,
'  `!!!!!&gt;;!!!'<font color="#AA7841"> ' !!!!&gt; &lt;!!&gt;.`!!;`&lt;!;!, !&gt;!! !!&gt;</font>4P"<font color="#AA7841">,' </font>`hcc$$??????" , <font color="#CCCCFF">?== hc,,</font><font color="#6699FF">dC??$$$$$$$$r </font><font color="#9999FF">.,..</font> ""&lt;$$"'$$c$$$$$$ ?$$$$$,.$"3$h,
!!;;,'```''''' <font color="#AA7841">' !!!!!!&gt; `!!!;'`!; `!!! !&gt;!!'!'  ,;</font> z$r .,. ,i <font color="#9999FF">$h`,c ? </font><font color="#CCCCFF">zP  "$,,,.</font><font color="#9999FF">&lt;$$$$F,cCL."""$$h.</font>`""d$$" $$"",$$.""")C,.cc$$$$
!!!!!!!' ,;!;, <font color="#AA7841"> !!!!'!!!! `!!!!;`!; `!! !&gt;!&gt;' ;!!!! </font>"?"<font color="#CC9966">-?" </font><font color="#EBDCAA">rcc,</font><font color="#9999FF">cF d"</font><font color="#CCCCAA">%  </font><font color="#CCCCFF">?"  c $$$L</font><font color="#9999FF"> $$$P d$$$$$$."$$$$$ </font>c " &lt;$"z$$??$$ "????????""
;;- ,;, !!!!!!<font color="#AA7841"> `!!!! &lt;!!!! &lt;!!!!;`!;'!  !!!! &lt;!!!!&gt;; </font>`;;;-=<font color="#EBDCAA"> ??'</font><font color="#9999FF">?F&lt;$ &lt;$ </font><font color="#CCCCFF">$$. $ ?".?</font><font color="#9999FF">-`"". ???????? .,,</font><font color="#CCCCAA">c</font><font color="#9999FF">,,</font><font color="#CCCCAA">c </font><font color="#9999FF">.,.. </font>"?L"??"'L = 4,'????
!;!!!!'`````!! <font color="#AA7841"> `!!!&gt; &lt;!!!&gt;'!!!!! ! ' ;!!!&gt;!!'!!!!,';.</font> `&lt;!!!!;;;,&gt; ..'<font color="#CCCCFF"> ?"")C</font>,c,c- &lt;$$$h4ccccccc <font color="#9999FF">$$$$$$P $$$$F</font>.,.<font color="#CCCCCC"> ,d$$P"</font>"""???=+-
!```'!<font color="#9999FF"> !!!!; ,; </font><font color="#AA7841">;'`!!; `!!! &lt;!!!!&gt;  ;&lt;!!''&gt;!! `&lt;!!!; `!;,</font>`&lt;!!!!!! z$$$$$ ??????".r`$$$$dCCCCCCC,<font color="#9999FF">zccccc .,cCC</font><font color="#CCCCCC"> d$$$$$$P </font>c$$$c<font color="#9999FF"> ;,;;</font>
<font color="#9999FF">!; '  `!!!!! !! </font><font color="#AA7841">`;.``!!; !!&gt;'!!!! ;!'`!! &lt;`!'';  `!!!; `!&gt;</font> &lt;!!! 4c$$$$$$ ccccccc$" """"""""?$$$$$$$$$$cc,<font color="#9999FF">"??</font><font color="#CCCCCC"> ???????"</font>z$$$$??<font color="#9999FF"> !',</font>
<font color="#9999FF">''' !; ! !!,`;!;  </font><font color="#AA7841">`!.'`!;`! &lt; `! `'' &lt;!';!;! !!&gt; ;`&lt;!!!; !;</font> cccccccc,,,.."$$$$$$P<font color="#9999FF"> $$$"z$$",c </font>?""<font color="#9999FF">.,c </font>$$$".zr ;!!!!!!; $P" <font color="#9999FF">.;;!''`</font>
<font color="#9999FF">;,;!!!;,. &lt;!;;!! </font><font color="#AA7841">!.`!!;,! '   '   .&lt;!!' !!! ;!!&gt; !&gt;.`&lt;!!!!!</font> $$$$CCLcccr?% $$$$$F<font color="#9999FF"> z"?"z$$",$$P&lt;$$$$". </font>,c$$P) !!!!!!!! "<font color="#9999FF">.&lt;'`,';! '</font></b>
</pre>

      </div> */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`

position: absolute;
top: 0;
left: 0;
width: 100vw;

.pree {
  color: yellow;
}
.van_1 {
    position: absolute;
    top: 0;
    margin-top: 36px;
    margin-right: -21%;
    right: 0;
    z-index: 0;
    opacity: 0.5;
    // -webkit-mask-image: -webkit-gradient(linear, right top, left top, 
    // from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  }

.van_2 {
    top: 0;
    position: absolute;
    margin-top: 36px;
    margin-left: -24.4%;
    left: 0;
    z-index: 0;
    opacity: 0.5;
    // -webkit-mask-image: -webkit-gradient(linear, left top, right top, 
    // from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  }


  .pree_van > * {
    font-family: monospace;
    white-space: pre-wrap;
    font-weight: bold;
    font-family: monospace;
    display: inline-block;
    font-family: monospace;
    unicode-bidi: isolate;
    margin: 1em 0px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-height: 1.2; /* Adjust line height for readability */
    width: 921px;
    max-width: 950px;
    color:rgb(18, 64, 231);
  }

  font {
    display: inline-block; /* Ensures proper word-breaking */
    overflow-wrap: break-word;
    word-wrap: break-word;
    
  }

   
  

  @keyframes resize {
    0% { opacity: 0; }
    10% { opacity: 0; width: 15%}
    50% { opacity: 0.001 ; width: 27%; }
    95% { opacity: 0.5; }
  }

  iframe {    
    animation: resize 1.1s;
    width: 75%;
    border: none; 
    min-height: 1060px; 
    zoom: 95%;
    overflow: hidden;
    scrolling: no;
    background: 'black';
    z-index: 2;
    padding-bottom: 40px;
  }


  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    z-index: -3;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 0px;
    top: 0;
  }



@media only screen and (max-width: 4300px) {
    @keyframes resize {
      0% { opacity: 0; }
      10% { opacity: 0; width: 20%}
      50% { opacity: 0.001 ; width: 30%; }
      95% { opacity: 0.5; }
    }

    iframe {    
      margin-left: -15px;
      animation: resize 1.1s;
      width: 37%;
      border: none; 
      min-height: 1060px; 
      zoom: 85%;
      overflow: hidden;
      scrolling: no;
      background: 'black';
      z-index: 2;
      padding-bottom: 40px;
    }

    .van_1 {
      top: 0;
      position: absolute;
      margin-top: 36px;
      margin-right: 0%;
      right: 0;
      z-index: 0;
      opacity: 0.4;
    }

    .van_2 {
      top: 0;
      position: absolute;
      margin-top: 36px;
      margin-left: 0%;
      left: 0;
      z-index: 0;
      opacity: 0.4;
    }
  }

  @media only screen and (max-width: 3200px) {

  iframe {
    width: 1150px;
  }
}


  @media only screen and (max-width: 2240px) {
    @keyframes resize {
      0% { opacity: 0; }
      10% { opacity: 0; width: 9940px}
      50% { opacity: 0.001 ; width: 1000px; }
      95% { opacity: 1; }
    }

    iframe {    
      animation: resize 1.1s;
      width: 1170px;
      border: none; 
      min-height: 1060px; 
      overflow: hidden;
      scrolling: no;
      background: 'black';
      z-index: 2;
      padding-bottom: 40px;
    }
      .van_1 {
      top: 0;
      position: absolute;
      margin-top: 36px;
      margin-right: 0%;
      right: 0;
      z-index: 0;
      opacity: 0.4;
    }

    .van_2 {
      top: 0;
      position: absolute;
      margin-top: 36px;
      margin-left: 0%;
      left: 0;
      z-index: 0;
      opacity: 0.4;
    }
  }

  @media only screen and (max-width: 1835px) {
   .van_1 {
      opacity: 1;
      -webkit-mask-image: -webkit-gradient(linear, right top, left top, 
      from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    }

  .van_2 {
      opacity: 1;
      -webkit-mask-image: -webkit-gradient(linear, left top, right top, 
      from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    }
  
  }

  @media only screen and (max-width: 1900px) {
    iframe {
      width: 1050px;
    }
  }

  @media only screen and (max-width: 1336px) {
    

    .van_1 {
      opacity: 1;
      -webkit-mask-image: -webkit-gradient(linear, right top, left top, 
      from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    }

  .van_2 {
      opacity: 1;
      -webkit-mask-image: -webkit-gradient(linear, left top, right top, 
      from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    }
  }

  @media only screen and (max-width: 900px) {

    .van_1 {
      opacity: 0;
    }

    .van_2 {
      opacity: 0;
    }

    .art_3 {
      opacity: 0;
    }

    .art_4 {
      opacity: 0;
    }


  }



  @media only screen and (max-width: 1250px) {
    


    @keyframes resize {
      0% { opacity: 0; }
      10% { opacity: 0; width: 9940px}
      50% { opacity: 0.001 ; width: 1000px; }
      95% { opacity: 1; }
    }

    iframe {    
      animation: resize 1.1s;
      width: 79%;
      border: none; 
      min-height: 1060px; 
      overflow: hidden;
      scrolling: no;
      background: 'black';
      z-index: 2;
      padding-bottom: 40px;
    }

    .van_1 {
      opacity: 0;
    }

  .van_2 {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 885px) {
    iframe {   
      min-height: 120vw;
    }
  }

  @media only screen and (max-width: 800px) {
    iframe {
      width: 100%;
      padding-bottom: 0px;
      min-height: 155vw;
    }
  }

  @media only screen and (max-width: 720px) {
    iframe {
      width: 100%;
      min-height: 155vw;
    }
    .van_1 {
      display: none;
    }
    .van_2 {
      display: none;
    }
    .art_3 {
      display: none;
    }
    .art_4 {
      display: none;
    }  
  }
  
`

export default resume
