import React, { useState } from "react"
import styled from "styled-components"
import "../assets/css/global.css"
import ContextConsumer from "../components/Context.js"

const Stars = () => {
  const [isActivated, setIsActivated] = useState(false); // State to track 
  function niceToMeetYou(str) {
    let pageWrapper = document.getElementById("wrapper")
  
    if (!pageWrapper) {
      return
    }

    if (window.location.pathname === "/resume") {
      let stars_1 = document.getElementsByClassName("pree")[0]
      let stars_1_wide = document.getElementsByClassName("pree_wide")[0]
      stars_1.style.transitionDuration = "1s"
      stars_1.style.color = "yellow"
      stars_1_wide.style.transitionDuration = "1s"
      stars_1_wide.style.color = "yellow"
      let stars_2 = document.getElementsByClassName("pree")[1]
      let stars_2_wide = document.getElementsByClassName("pree_wide")[1]
      stars_2.style.transitionDuration = "1s"
      stars_2.style.color = "yellow"
      stars_2_wide.style.transitionDuration = "1s"
      stars_2_wide.style.color = "yellow"
    } else {
      let stars_1 = document.getElementsByClassName("pree")[0]
      let stars_1_wide = document.getElementsByClassName("pree_wide")[0]
      stars_1.style.transitionDuration = "1s"
      stars_1.style.color = "rgb(18, 64, 231)"
      stars_1_wide.style.transitionDuration = "1s"
      stars_1_wide.style.color = "rgb(18, 64, 231)"
      let stars_2 = document.getElementsByClassName("pree")[1]
      let stars_2_wide = document.getElementsByClassName("pree_wide")[1]
      stars_2.style.transitionDuration = "1s"
      stars_2.style.color = "rgb(18, 64, 231)"
      stars_2_wide.style.transitionDuration = "1s"
      stars_2_wide.style.color = "rgb(18, 64, 231)"
    }

    // pageWrapper.style.background = "linear-gradient(to bottom, #000000 0% ,rgb(10, 13, 15) 10%, rgb(10, 13, 15) 20%, rgb(13, 16, 18) 25%, rgb(17, 22, 25) 50%, rgb(17, 22, 25) 100%)"
  
    if (str === "reverse") {
      pageWrapper.style.animation = "ReverseAnimation 5s ease"
      pageWrapper.style.backgroundPosition = "50% 100%"
      return
    }
  
    if (str === "forward") {
      pageWrapper.style.backgroundPosition = "50% 0%" // change to 26 if you want
      pageWrapper.style.animation = "Animation 7s ease"
      pageWrapper.style.transition = "background-position 1s"
      // if (document.location.pathname === "/" || document.location.pathname === "/resume" || (document.location.pathname.split("/")[1] === "articles") || (document.location.pathname === "/articles") ||  (document.location.pathname === "/projects")) {
      //   pageWrapper.style.backgroundPosition = "50% 0%"
        
      // } 
      
      // else if (document.location.pathname === "/articles" ||  document.location.pathname === "/projects") {
      //   pageWrapper.style.backgroundPosition = "50% 0%" // change to 26 if you want

      // }
      return
    }
  }

  let currentWindow = null;

  if (typeof window !== "undefined") {
    currentWindow = window.location.pathname
  }


  return (
    <Wrapper id="wrapper">
            <ContextConsumer>
            {({ data }) => {
                    if (data.isClicked) {
                    niceToMeetYou("forward");
                    }
                    if (currentWindow === "/") {
                      if ((!data.isClicked) && data.isToggled && (!data.isActivated)) {
                        niceToMeetYou("reverse");
                        }
                    } 
                    else if (currentWindow !== "/") {
                      if ((!data.isClicked) && data.isToggled && ((!data.isActivated) || (!isActivated))) {
                        niceToMeetYou("reverse");
                      }
                    }
                   

                    return (
                    <div>
                        <div class="art_1">
                            <pre class="pree">
                            ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　          ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　
                            </pre>
                            <pre class="pree_wide">
                            ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　, 

                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　   　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ 

                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　  　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　          ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.  
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　   　　　⠀⠀⠀⠀⠀* ⠀
                    ⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.

                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀ 
                    ⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　
                    　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　                            ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀

                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀   ⠀⠀.　　
                    　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　
                    ,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　
                    　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ 
                    .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* 
                    ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　          ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀
                    ⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀
                    ⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*   ✦⠀
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　 ✦　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀ ✦
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀
                    ⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　⠀　   　　　,　　　　　　　　　*   ✦
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　                            ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.   ✦
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀
                    ⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*  ✦
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　  ✦　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　
                    　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　 ✦ 
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    ⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*  ✦
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　 ✦ 
                    　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　. ✦⠀
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀, 
                    　　　　　✦⠀　   　　　          ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　 ✦　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　  ✦⠀
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* 
                    ⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.

                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*   ✦
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　✦　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀
                    　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　⠀　   　　　,
                    　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,  ✦
                    　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　   ✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　   
                    　　　˚　　　　　　　  　ﾟ  　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　  ⠀.
                    　　　　　 .　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　 　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　   　　⠀⠀⠀ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　                            ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　   ✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.

                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍✦⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀    
                    ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　   　✦  ⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　    ⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　 
                    .
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　✦　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦
                    ⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀               ✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀           ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　         ✦⠀　   　　　,　　　　　　　　　*  ✦
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　          ✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀. 
                    　　　　　.　　　　　　　　　　⠀                  ✦ 
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.✦
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　          ⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　
                    
                    .　　　　　　　　　　　　　. 　　⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀✦⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　
                       　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀ ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　✦　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.

                    .　　　　　　　　　　　　　. 　　⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　✦⠀　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　⠀
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　*　　⠀.
                    　　　　　.　　　　　　　　　　⠀✦
                    　˚　　　　　　　　　　　　　　*
                    .⠀ 　　　　　　　　　　.　　　　　　　　.
                    　　　　　✦⠀　   　　　,　　    　　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　⠀⠀⠀✦ ⠀
                     ⠀　　　　　　　　　　　　　　⠀⠀⠀⠀⠀* ⠀⠀⠀.　　　　　　　　　　. ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀✦⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀ ⠀ ⠀⠀⠀⠀⠀⠀.　　　　　　　　　　　　　.　　　ﾟ .　　　　　　　　　　　　　. 　　　　　　　　　　　　　　　✦ 　　　　　,　　　　　　　.
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                    　　　　　　*　　　　　　　　　　　.
                    .　　　　　　　　　　　　　. 　　✦⠀　   　　　,　　　　　　　　　*
                    　　　　　⠀　　　　⠀　　,
                    ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀.　　　　　 　　⠀　　　⠀.　
                    　　˚　　　⠀　⠀  　　,　　　　　　.
                    　　　　　　　　　　　　　.
                    　　　　　　*⠀　　⠀  　　　　　⠀✦⠀　
                    　　　　　　*　　　　　　　　　　　　　　　　　　.
                    　　　　.　　　　.　　　
                    　　　　　　　　　　　.
                    　　　　　　　
                    　　　˚　　　　　　　　ﾟ　　　　　.
                    　.⠀　⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀‍⠀,
                    　　　　　✦⠀　   　　　
                            </pre>
                        </div>
                        <div class="art_2">
                            <pre class="pree">
                            .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .         .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .      
                            </pre>
                            <pre class="pree_wide">
                            .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .         .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .                             .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .         .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .                             .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .         .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .                             .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  .         .                         '           .           +                                             .                                  +          
                        *        .                        '       +                                 .                                  .                         .      
                    +      .                '                       .                                             '                ' *          .  '                *   
                                .                                '                             +                 *         .     '                                       
                    '     +             .                 +                   '       '    +        +    +                            +         * '           +           
                            *                       '                          '                          '         *                              +        *     .   
                            '     *              '               '        .  +       .     '                                                                            
                        .                                '      *      .                '        '      .          .                         +                        .  
                            .                             '           .                    '                . .             '     '    .          *          '        
                    +    *      +                            '                                    .                                         ' '                 .   +  
                                        *                                      +         .                               * '                                             
                                    .                    .               +                                 '         '                                       +    *   .
                    +                                         '      . .                                                    '                                           
                        '                    '       *    '             '                             .       .   .     '                 .  '   .                    
                            +                                                           +         *       .                 .     .                       '     .      
                                                                +                  .                            '         +                                             
                    '      +       + '  *     +                                                                       *                       +  .                      
                    .                                     .              +        '  '                       +  ' .                      '               '            
                            .            '                             '     '              '                                                                         
                        '                         .    +  +   ' .    '               .                   .                            +                               
                                                                            . .                         +            .                                           .      
                                .  .                 '       .                         +                        *         *     .     *               '   . .         
                                                            .         ' .                                                                           +    '             
                        *  .          +               +                                      +  .         .                *                  *     .              ' '   
                                                                            .        +  '            '           .    *            .                                    
                                        .  '             ' .         *            .         .                                 *  .     +                       +        
                    '        .                                   .                   . +          .    +      .       .                                               
                                    +                           +            '            .                                  '                '  '                 '   
                    +    .                                                                                         *       '                  .             .      .      
                                                        .       .                                          .                                                '       . 
                                '   +    +   * '     '                         *            .          .        .   '             '          '              *    '       
                                                            .      '                                                                                                ' 
                        '        '       +                                                       .                    '           +      '      .       '         .     
                                            +             .              .                                                   .                        '          '       
                                                            '                  '     .        '  .       '   .        .                + +  .        *           '    
                        +                   +                             '                  '                  '                                  '         .  . 
                            </pre>
                        </div>
                    </div>
                    )
                }
                }
            </ContextConsumer>
        
    </Wrapper>
  )
}




const Wrapper = styled.div`

position: absolute;
@keyframes translate {
  0% { transform: translateX(0) } 
  100% { transform: translateX(25%) }
}

@keyframes translate_2 {
  0% { transform: translateX(25%) } 
  100% { transform: translateX(35%) }
}

@keyframes shimmer {
    0% { opacity: 0.1 } 
    25% { opacity: 0.2 }
    50% { opacity: 0.1 }
    75%: { opacity: 0.2 }
    100% { opacity: 0.1 }
}

@keyframes shimmer_2 {
  0% { font-size: 16px } 
  25% { font-size: 12px }
  50% { font-size: 16px } 
  75% { font-size: 12px }
  100% { font-size: 16px }
}

@keyframes shimmer_wide {
  0% { font-size: 18px } 
  25% { font-size: 22px }
  50% { font-size: 18px } 
  75% { font-size: 22px } 
  100% { font-size: 18px } 
}

padding-bottom: 60px;

.art_1 {
    position: absolute;
    margin-top: -50%;
    margin-left: 0%;
    left: 0;
    z-index: 0;
    animation: shimmer 3.5s infinite ease-in-out;
    transition: transform 11s ease-out;
    text-align: center;
    
    font-size: 14px;
    // color: white;
  }

  .art_2 {
    position: absolute;
    margin-top: -50%;
    margin-left: 0%;
    left: 0;
    z-index: 0;
    text-align: center;
    animation: shimmer 3.5s 1.75s infinite ease-in-out;
    transition: transform 11s ease-out;
    
    font-size: 14px;
    // color: white;
  }

  .pree  {
    width: 100%;
    font-family: monospace;
    white-space: pre-wrap;
    font-weight: bold;
    font-family: monospace;
    display: inline-block;
    font-family: monospace;
    unicode-bidi: isolate;
    margin: 1em 0px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-height: 1.2; /* Adjust line height for readability */
    color:rgb(18, 64, 231);
    // font-size: 12px;
    // animation: shimmer_2 60s infinite linear;
    // color : white
  }

  .pree_wide  {
    width: 100%;
    font-family: monospace;
    white-space: pre-wrap;
    font-weight: bold;
    font-family: monospace;
    // display: inline-block;
    font-family: monospace;
    unicode-bidi: isolate;
    margin: 1em 0px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-height: 1.2; /* Adjust line height for readability */
    color:rgb(18, 64, 231);
    // font-size: 12px;
    // animation: shimmer_2 60s infinite linear;
    // color : white
    display: none;
  }

  .circularImage {
    border-radius: 1%;
    margin: 0 20% 0 7%;
  }

  #meeting_button {  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
    text-decoration: none;
    color: white;
    background-color:rgb(11, 16, 19);
    border-style: none;
    font-family: "Yantramanav", sans-serif;
    transition-duration: 0.2s;
    border-radius: 10px;
    box-shadow: 0 9px rgb(11, 17, 19);
    margin-top: 0px;
    padding-top: 20px;
    border-style: solid;
    border-color: rgb(11, 17, 19);
    border-width: 1px;
    user-select: none;
    &:hover {
      color: #ffa500;
    }
    &:active {  
      box-shadow: 0 5px rgb(11, 17, 19);
      transform: translateY(8px);
      border-color: rgb(11, 17, 19);
      transition: transform 0.1s;
    }
    font-size: 70px;
    padding-bottom: 15px;
  }


    // background: linear-gradient(to bottom,rgb(12, 12, 33) 0%, rgb(9, 8, 22) 3%, rgb(9, 8, 22) 10%, rgb(17, 20, 21) 12%, rgb(28, 29, 31) 65%, rgb(28, 29, 31) 70%, rgb(28, 29, 31)  75%, rgb(28, 29, 31) 100%);
  // background: linear-gradient(to bottom,rgb(12, 33, 15) 0%, rgb(9, 8, 22) 3%, rgb(9, 8, 22) 10%, rgb(12, 12, 33) 12%, rgb(28, 29, 31) 65%, rgb(28, 29, 31) 70%, rgb(28, 29, 31)  75%, rgb(28, 29, 31) 100%);


  background: linear-gradient(to bottom,rgb(2, 1, 14) 0%, rgb(9, 8, 22) 6%, rgb(9, 8, 22) 10%, rgb(12, 12, 18) 17%, rgb(28, 29, 31) 65%, rgb(28, 29, 31) 70%, rgb(28, 29, 31)  75%, rgb(28, 29, 31) 100%);
  background-position: 50% 100%;
  background-size: 400% 400%;
  display: flex;
  width: 100%;
  z-index: 0;
  pointer-events: none;
  height: 200vh;
  min-height: 1000px;
  max-height: 1550px;

  
  

  @keyframes Animation { 
    0%{background-position: 50% 100%}
    100%{background-position: 50% 0%} // change to 26 if you want
  }

  @keyframes ReverseAnimation { 
    0%{background-position: 50% 0%} // change to 26 if you want
    100%{background-position: 50% 100%}
  }

  .section {
    padding-top: 35px;
    overflow-x: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 22px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: 1000px;
    gap: 25px;
    margin: 0 0px 0 0px;
  }

  .header {
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    gap: 15px;
    z-index: 2;
  }

  .photo_header {
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: center;
    gap: 15px;
    z-index: 2;
  }

  .photo {
    padding: 0px 0px 0px 0px;
    width: 410px;
    align-self: flex-end;
    z-index: 2;
  }

  h1 {
    font-family: "Yantramanav", sans-serif;
    font-size: 60px;
    font-weight: 700;
    color: white;
    display: inline;
    margin-bottom: -5px;
    align-self: flex-start;
    margin-top: 0px;
    margin-left: 2px;
    line-height: 1.2;
    z-index: 2;
  }

  p {
    font-family: "Yantramanav", sans-serif;
    font-size: 25px;
    font-weight: 500;
    color: #bebebe;
    margin: 0px 0px 0px 0px;
    margin-left 2px;
  }

  /* #resumePDF {
    display: flex;
    padding: 15px 25px;
    justify-content: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    background-color: #1f282e;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #141b1f;
    margin-top: 10px;
    width: 80%;
    font-family: "Yantramanav", sans-serif;
    font-size: 25px;
    font-weight: 700;
    color: white;
    margin-top: 30px;
  }

  #resumePDF:hover {
    background-color: #3e8e41;
  }

  #resumePDF:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #181f24;
    transform: translateY(4px);
  } */

  .intro {
    font-weight: 400;
    margin-bottom: 15px;
    transition: all 2s ease;
    z-index: 2;
  }

  .project_section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .project_content {
    display: flex;
    max-width: 1000px;
    gap: 25px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    z-index: 2;
  }

  .project_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #181f24;
    flex-basis: 0;
    flex-grow: 1;
    padding: 15px;
    border-radius: 10px;
  }

  .project_h2 {
    font-family: "Yantramanav", sans-serif;
    font-size: 35px;
    font-weight: 700;
    color: white;
    margin-bottom: -5px;
    margin-top: -5px;
  }

  .technologies {
    font-size: 20px;
    color: white;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  img {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  .project_p {
    font-family: "Yantramanav", sans-serif;
    font-size: 20px;
    font-weight: 100;
    color: #bebebe;
    margin: 15px 0px 0px 0px;
  }

  .project-buttons {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    flex-grow: 1;
    gap: 15px;
    justify-content: flex-end;
  }

  .project-buttons > * {
    text-decoration: none;
    color: white;
    background-color: #1e282e;
    border-style: none;
    font-family: "Yantramanav", sans-serif;
    font-size: 21px;
    font-weight: 600;
    transition-duration: 0.01s;
    border-radius: 10px;
    box-shadow: 0 9px #131a1d;
    margin-top: -5px;
    border-style: solid;
    border-color: #1e282e;
    border-width: 1px;
    user-select: none;
    &:hover {
      color: #ffa500;
      font-size: 21px;
    }
    &:active {
      box-shadow: 0 5px #181f24;
      transform: translateY(4px);
      transition-duration: 0.01s;
      border-color: #1e282e;
    }
  }

  .project_github {
    align-self: flex-end;
    padding: 15px 65px 15px 65px;
  }

  .project_demo {
    align-self: flex-end;
    padding: 15px 70px 15px 70px;
  }

  #disabled-button {
    border-style: dashed;
    border-color: #242f36;
    border-width: 5px;
    width: 197px;
    background-color: #181f24;
    box-shadow: 0 9px #131a1d;
    &:hover {
    }
    &:active {
      transform: none;
    }
  }

  @media only screen and (max-width: 4500px) {

    .pree {
      display: none;
    }

    .pree_wide {
      display: inline-block;
    }

    .art_1 {
      margin-top: -30%;
      font-size: 12.5px;
    }

    .art_2 {
      margin-top: -40%;
      font-size: 14px;  
    }
  }


  @media only screen and (max-width: 3900px) {
    .art_1 {
      margin-top: -30%;
    }

    .art_2 {
      margin-top: -40%;
    }
  }


  @media only screen and (max-width: 3400px) {
    .art_1 {
      margin-top: -40%;
    }

    .art_2 {
      margin-top: -50%;
    }
  }

  @media only screen and (max-width: 3200px) {
    .art_1 {
      margin-top: -50%;
    }

    .art_2 {
      margin-top: -60%;
    }
  }

   @media only screen and (max-width: 3100px) {
    .art_1 {
      margin-top: -60%;
    }

    .art_2 {
      margin-top: -70%;
    }
  }

  @media only screen and (max-width: 2900px) {
    .art_1 {
      margin-top: -80%;
    }

    .art_2 {
      margin-top: -90%;
    }
  }

  @media only screen and (max-width: 2700px) {
    .art_1 {
      margin-top: -75%;
      font-size: 11.5px;
    }

    .art_2 {
      margin-top: -85%;
      font-size: 12px;  
    }
  }

  @media only screen and (max-width: 2400px) {
    .art_1 {
      margin-top: -85%;
      
    }

    .art_2 {
      margin-top: -95%;
      
    }
  }

  @media only screen and (max-width: 2300px) {
    .art_1 {
      margin-top: -95%;
      
    }

    .art_2 {
      margin-top: -100%;
      
    }
  }

   @media only screen and (max-width: 2200px) {
    .art_1 {
      margin-top: -105%;
      
    }

    .art_2 {
      margin-top: -110%;
      
    }
  }

  @media only screen and (max-width: 2100px) {
    .art_1 {
      margin-top: -115%;
    }

    .art_2 {
      margin-top: -125%;
    }
  }

  

  @media only screen and (max-width: 1900px) {
    .art_1 {
      margin-top: -135%;
    }

    .art_2 {
      margin-top: -145%;
    }
  }

  @media only screen and (max-width: 1800px) {
    .art_1 {
      margin-top: -160%;
    }

    .art_2 {
      margin-top: -170%;
    }
  }

  @media only screen and (max-width: 1700px) {
    .art_1 {
      margin-top: -170%;
    }

    .art_2 {
      margin-top: -180%;
    }
  }

  @media only screen and (max-width: 1600px) {
    .art_1 {
      margin-top: -130%;
      font-size: 9.5px;
    }

    .art_2 {
      margin-top: -140%;
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 1400px) {

    

    .art_1 {
      margin-top: -180%;
    }

    .art_2 {
      margin-top: -190%;
    }
  }

  // @media only screen and (max-width: 1320px) {
  //   .art_1 {
  //     margin-top: -93%;
  //   }

  //   .art_2 {
  //     margin-top: -103%;
  //   }
  // }

  @media only screen and (max-width: 1200px) {
    .art_1 {
      margin-top: -260%;
    }

    .art_2 {
      margin-top: -270%;
    }
  }

  @media only screen and (max-width: 1110px) {
    .art_1 {
      margin-top: -300%;
    }

    .art_2 {
      margin-top: -300%;
    }
  }

  @media only screen and (max-width: 1050px) {
    .art_1 {
      margin-top: -330%;
    }

    .art_2 {
      margin-top: -360%;
    }
  }

  @media only screen and (max-width: 950px) {

    .pree {
        display: inline-block;
      }

      .pree_wide {
        display: none;
      }
    .art_1 {
      margin-top: -90%;
    }

    .art_2 {
      margin-top: -100%;
    }
  }

  @media only screen and (max-width: 800px) {
  
    .art_1 {
      margin-top: -110%;
    }

    .art_2 {
      margin-top: -120%;
    }
  }

  @media only screen and (max-width: 699px) {
    .art_1 {
      margin-top: -175%;
    }

    .art_2 {
      margin-top: -145%;
    }
  }

  @media only screen and (max-width: 599px) {
    .art_1 {
      margin-top: -160%;
      font-size: 8px;
    }

    .art_2 {
      margin-top: -140%;
      font-size: 8px;
    }
  }

  @media only screen and (max-width: 500px) {
    .art_1 {
      margin-top: -235%;
      font-size: 8px;
    }

    .art_2 {
      margin-top: -200%;
      font-size: 8px;
    }
  }

  @media only screen and (max-width: 400px) {
    .art_1 {
      margin-top: -315%;
      font-size: 8px;
    }

    .art_2 {
      margin-top: -295%;
      font-size: 8px;
    }
  }

  @media only screen and (max-width: 352px) {
    .art_1 {
      margin-top: -445%;
      font-size: 8px;
    }

    .art_2 {
      margin-top: -435%;
      font-size: 8px;
    }
  }

   @media only screen and (max-width: 299px) {
    .art_1 {
      margin-top: -445%;
      font-size: 6px;
    }

    .art_2 {
      margin-top: -435%;
      font-size: 6px;
    }
  }

   @media only screen and (max-width: 1150px) {

   h1 {
      font-size: 55px;
    }

    p {
      font-size: 25px;
    }

    #meeting_button {
      padding: 5% 0 4% 0;
    }

    .circularImage {
      width: 300px;
    }

    .section {
      padding-top: 35px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      margin-bottom: 22px;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .project_section {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    }

    .header {
      overflow: hidden;
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;
      flex-basis: 0;
      align-items: center;
      gap: 15px;
      z-index: 2;
    } 
  }

  @media only screen and (max-width: 1000px) {
    h1 {
      font-size: 50px;
    }

    .project_github {
      align-self: flex-end;
      padding: 10px 15px 10px 15px;
    }

    .project_demo {
      align-self: flex-end;
      padding: 10px 20px 10px 20px;
    }

    #disabled-button {
      display: none;
    }
    
    .circularImage {
      margin-left: 60px;
    }
  }

  @media only screen and (max-width: 880px) {
  .section {
      margin-bottom: -0px;
    }
    h1 {
      font-size: 50px;
    }
    
    p {
      font-size: 25px;
    }

    .photo {
      width: 350px;
    }

    .circularImage {
      margin-left: 0px;
    }
  }

  @media only screen and (max-width: 794px) {
    

    p {
      margin-bottom: 100px;
    }
    
    h1 {
      font-size: 50px;
    }
    #meeting_button {
      padding: 8% 0 8% 0;
      box-shadow: none;
      border-style: none;
    }
    .photo_header {
      display: none;
    }
  }

  @media only screen and (max-width: 730px) {
    .project_content {
      display: flex;
      flex-flow: column nowrap;
    }
    .photo_header {
      display: none;
    }

    #meeting_button {
      height: 35%;
    }
  }

  @media only screen and (max-width: 670px) {
    .project_section {
      margin-top: 25px;
    }
  }

  @media only screen and (max-width: 489px) {
    gap: 25px;
    .project_github {
      align-self: flex-end;
      padding: 10px 15px 10px 15px;
    }

    .project_demo {
      align-self: flex-end;
      padding: 10px 20px 10px 20px;
    }

    #disabled-button {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    .header {
      gap: 10px;
    }
    h1 {
      font-size: 45px;
    }

    p {
      font-size: 25px;
    }
  }

  @media only screen and (max-width: 350px) {
    .project_h2 {
      font-size: 30px;
    }
    .content {
      margin-right: 15px;
    }
  }

  @media only screen and (max-width: 335px) {
    h1 {
      font-size: 35px;
    }
  }
`

export default Stars